import React, { useContext, useState, useEffect } from 'react';
// @ts-ignore
import TextTransition, { presets } from 'react-text-transition';
import './ServicesBoxVerticalItem.css';
// Resources
import { WPService, LanguageData } from '../../../../resources/interfaces';
// Context
import { LanguageContext } from '../../../../Context/LanguageContext';
// JSON
const languageData: LanguageData = require('../../../../resources/languageData.json');

interface Props {
  service: WPService;
}

export default function ServicesBoxVerticalItem(props: Props) {
  // Props
  const { service } = props;
  // Context
  const [language] = useContext(LanguageContext);
  // JSON
  const { serviceCopyLinkText } = languageData.language[
    language
  ].page['services'];
  // State
  const [isCopied, setIsCopied] = useState<boolean>(false);

  useEffect(() => {
    // Set state back to false after 2 seconds
    const timeout = setTimeout(() => {
      if (isCopied) setIsCopied(false);
    }, 2000);

    return () => {
      if (isCopied) clearTimeout(timeout);
    };
  }, [isCopied]);

  const handleCopyClick = (): void => {
    setIsCopied(true);
    navigator.clipboard.writeText(
      service.acf.hyperlink ? service.acf.hyperlink : ''
    );
  };

  return (
    <li
      className="card row mb-3 pt-3 pb-3"
      // @ts-ignore
      tabIndex="0"
    >
      <div
        className="service-box-title mb-3"
        dangerouslySetInnerHTML={{ __html: service.title.rendered }}
      />
      <div className="service-box-themes">
        <div className="copy-button">
          <button onClick={() => handleCopyClick()}>
            <TextTransition
              text={
                isCopied ? serviceCopyLinkText.copied : serviceCopyLinkText.copy
              }
              spring={presets.gentle}
              inline
            />
          </button>
        </div>
      </div>
    </li>
  );
}
import React from 'react';
import { TutorialStepsProps } from '../../../../resources/interfaces';

export default function TutorialSteps(props: TutorialStepsProps) {
  const { step, stepCount, setStep } = props;

  return (
    <>
      <ul>
        <li id="tutorial-step-guider" style={{ top: `${60 * step}px` }}></li>
        {[...Array(stepCount)].map((_, index) => (
          <li
            key={index}
            onClick={() => setStep(index)}
            className={index === step ? 'step active' : 'step'}
          >
            {index + 1}
          </li>
        ))}
      </ul>
    </>
  );
}
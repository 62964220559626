import React, { useContext, useState, useEffect } from 'react';
// @ts-ignore
import TextTransition, { presets } from 'react-text-transition';
import './ServicesBoxItem.css';
// Resources
import { WPService, LanguageData } from '../../../../resources/interfaces';
// Context
import { LanguageContext } from '../../../../Context/LanguageContext';
// JSON
const languageData: LanguageData = require('../../../../resources/languageData.json');

interface Props {
  service: WPService;
  tagList: string[];
}

export default function ServicesBoxItem(props: Props) {
  // Props
  const { service, tagList } = props;

  // Context
  const [language] = useContext(LanguageContext);
  // JSON
  const { serviceThemesText, serviceCopyLinkText } = languageData.language[
    language
  ].page['services'];
  // State
  const [isCopied, setIsCopied] = useState<boolean>(false);

  useEffect(() => {
    // Set state back to false after 2 seconds
    const timeout = setTimeout(() => {
      if (isCopied) {
        setIsCopied(false);
      }
    }, 2000);

    return () => {
      if (isCopied) clearTimeout(timeout);
    };
  }, [isCopied]);

  const handleCopyClick = (): void => {
    setIsCopied(true);
    navigator.clipboard.writeText(
      service.acf.hyperlink ? service.acf.hyperlink : ''
    );
  };

  return (
    <li key={service.id} className="card fade-in col-4">
      <div className="card-body">
        <div className="card-text">
          <h5 className="truncation">{service.title.rendered}</h5>
        </div>
      </div>
      <div className="footer">
        {tagList.length > 0 ? (
          <div className="service-themes">
            {serviceThemesText}: {tagList}
          </div>
        ) : null}
      </div>
      <div className="copy-button">
        <div>
          <button onClick={() => handleCopyClick()}>
            <TextTransition
              text={
                isCopied ? serviceCopyLinkText.copied : serviceCopyLinkText.copy
              }
              spring={presets.gentle}
              inline
            />
          </button>
        </div>
      </div>
    </li>
  );
}
import React, { useState, useContext, useEffect } from 'react';
import InputGroup from 'react-bootstrap/InputGroup';
import FormControl from 'react-bootstrap/FormControl';
import './Services.css';
// Components
import ServicesBox from './ServicesBox/ServicesBox';
import ServicesBoxVertical from './ServicesBoxVertical/ServicesBoxVertical';
import ServicesTags from './ServicesTags/ServicesTags';
import ServicesTagsSelector from './ServicesTagsSelector/ServicesTagsSelector';
import ServiceTypeSelector from './ServiceTypeSelector/ServiceTypeSelector';
// Hooks
import useWindowDimensions from '../../Hooks/useWindowDimensions';
// Resources
import {
  LanguageData,
  WPTag,
  WPTagArray,
  WPService,
  WPArray,
  Tag,
} from '../../resources/interfaces';
import { Wordpress } from '../../resources/Wordpress';
// Context
import { LanguageContext } from '../../Context/LanguageContext';
// JSON
const languageData: LanguageData = require('../../resources/languageData.json');

export default function Services() {
  // Hooks
  const { isMobile } = useWindowDimensions();
  // Context
  const [language] = useContext(LanguageContext);
  // JSON
  const { title, searchText, tagListText } = languageData.language[
    language
  ].page['services'];
  // State - tags
  const [tags, setTags] = useState<Tag[]>([]);
  const [activeTags, setActiveTags] = useState<number[]>([]);
  const [tagPageCount, setTagPageCount] = useState<number>(0);
  const [currentTagPage, setCurrentTagPage] = useState<number>(0);
  // State - services
  const [servicesIsLoading, setServicesIsLoading] = useState<boolean>(false);
  const [services, setServices] = useState<WPService[]>([]);
  const [serviceType, setServiceType] = useState<string>('all');
  const [currentServicesPage, setCurrentServicesPage] = useState<number>(0);
  const [servicesPageCount, setServicesPageCount] = useState<number>(0);
  // State - search
  const [search, setSearch] = useState<string>('');

  // Reset States when switching between Desktop/Mobile
  useEffect(() => {
    setTags([]);
    setServices([]);
    setCurrentTagPage(0);
    setCurrentServicesPage(0);
    if (isMobile) setActiveTags([]);
  }, [isMobile, language]);

  // Fetch Service Tags
  useEffect(() => {
    let isCancelled = false;
    Wordpress.tagServices()
      .page(currentTagPage + 1)
      .perPage(isMobile ? 6 : 8)
      .then((response: WPTagArray) => {
        if (!isCancelled) {
          const newTags = response.map((tag: WPTag) => {
            const getTitle = (tag: WPTag): string => {
              const fallbackTitle = tag.name;
              const ptTitle = tag.name_pt;
              const enTitle = tag.name_en;
              if (language === 'PT')
                return ptTitle === ''
                  ? fallbackTitle
                  : ptTitle ?? fallbackTitle;
              else if (language === 'EN')
                return enTitle === ''
                  ? fallbackTitle
                  : enTitle ?? fallbackTitle;
              else return fallbackTitle ?? '';
            };
            return {
              id: tag.id,
              name: getTitle(tag),
            };
          });

           (isMobile && currentTagPage > 0) ?
            setTags(
              Array.from( new Set([...tags, ...newTags].filter((tag: WPTag) => tag.id)))
            )
           : setTags(newTags);

          setTagPageCount(response._paging.totalPages);
        }
      })
      .catch((error: Error) => console.log(error));

    return () => {
      isCancelled = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [language, currentTagPage, isMobile]);

  // Fetch Services Posts
  useEffect(() => {
    const parseResponse = (response: WPArray): WPService[] => {
      return response.map((service: WPService) => {
        const getTitle = (service: WPService): string => {
          const fallbackTitle = service.title.rendered;
          const ptTitle = service.acf.name_pt;
          const enTitle = service.acf.name_en;
          if (language === 'PT')
            return ptTitle === '' ? fallbackTitle : ptTitle ?? fallbackTitle;
          else if (language === 'EN')
            return enTitle === '' ? fallbackTitle : enTitle ?? fallbackTitle;
          else return fallbackTitle ?? '';
        };
        return {
          id: service.id,
          title: {
            rendered: getTitle(service),
          },
          content: {
            rendered: service.content.rendered,
          },
          tag_services: service.tag_services,
          acf: {
            hyperlink: service.acf.hyperlink,
            name_pt: service.acf.name_pt,
            name_en: service.acf.name_en,
          },
        };
      });
    };
    // Fetch Services
    setServicesIsLoading(true);
    let isCancelled = false;
    fetchServices()
      .then((response: WPArray) => {
        if (!isCancelled) {

          (isMobile && currentServicesPage > 0) ?
            setServices([...services, ...parseResponse(response)])
          : setServices(parseResponse(response));

          setServicesPageCount(
            response._paging ? response._paging.totalPages : 0
          );

          setServicesIsLoading(false);
        }
      })
      .catch((error: Error) => console.log(error));

    return () => {
      if (!isMobile && !(currentTagPage > 0)) isCancelled = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    activeTags,
    currentServicesPage,
    search,
    serviceType,
    language,
    isMobile,
  ]);

  const fetchServices = async (): Promise<WPArray> => {
    // Query Services
    return (
      Wordpress.services()
        .search(search)
        .page(currentServicesPage + 1)
        .perPage(6)
        .param('service_type', serviceType === 'all' ? null : serviceType)
        .param('tag_services', activeTags)
        .then((response: WPArray) => {
          return response;
        })
        .catch((error: Error) => console.log(error))
    );
  };

  // Toggle a tag activation state on click
  const handleTagClick = (id: number): void => {
    setCurrentServicesPage(0);
    (isMobile) ? setActiveTags(activeTags.includes(id) ? [] : [id])
    :
      setActiveTags(
        activeTags.includes(id)
          ? activeTags.filter((tagID) => tagID !== id)
          : Array.from(new Set([...activeTags, id]))
      );
  };

  const handleInputSearch = (text: string) => {
    if (text.length > 2) {
      setCurrentServicesPage(0);
      setSearch(text);
    } else if (search !== '') {
      setCurrentServicesPage(0);
      setSearch('');
    }
  };

  const handleServiceTypeClick = (service: string) => {
    setCurrentServicesPage(0);
    (service === serviceType) ? setServiceType('all') : setServiceType(service);
  };

  const handleSetCurrentServicesPage = (page: number): void => {
    if (!servicesIsLoading) setCurrentServicesPage(page);
  };

  return (
    <section className="section">
      <div className="service-type-selector">
        <div className="section-title row">
          {!isMobile ? (
            <h2 className="col-2">
              <div>{title}</div>
            </h2>
          ) : null}
          <div className={!isMobile ? 'col-10' : 'col-12'}>
            <ServiceTypeSelector
              serviceType={serviceType}
              setServiceType={handleServiceTypeClick}
            />
          </div>
        </div>
      </div>
      <div className="section-container">
        {!isMobile ? (
          <>
            <ServicesTags
              tags={tags}
              activeTags={activeTags}
              tagPageCount={tagPageCount}
              currentTagPage={currentTagPage}
              setCurrentTagPage={setCurrentTagPage}
              handleTagClick={handleTagClick}
              handleInputSearch={handleInputSearch}
            />
            <ServicesBox
              services={services}
              servicesIsLoading={servicesIsLoading}
              pageCount={servicesPageCount}
              currentServicesPage={currentServicesPage}
              setCurrentServicesPage={handleSetCurrentServicesPage}
            />
          </>
        ) : (
          <>
            <div id="service-search">
              <InputGroup>
                <FormControl
                  placeholder={searchText}
                  aria-label={searchText}
                  aria-describedby="basic-addon1"
                  onChange={(event) => handleInputSearch(event.target.value)}
                />
              </InputGroup>
            </div>
            <div id="services-tag-title-vertical" className="mt-2 mb-2">
              {tagListText}
            </div>
            <ServicesTagsSelector
              tags={tags}
              activeTags={activeTags}
              tagPageCount={tagPageCount}
              currentTagPage={currentTagPage}
              setCurrentTagPage={setCurrentTagPage}
              onTagClick={handleTagClick}
            />
            <ServicesBoxVertical
              services={services}
              servicesIsLoading={servicesIsLoading}
              pageCount={servicesPageCount}
              currentServicesPage={currentServicesPage}
              setCurrentServicesPage={handleSetCurrentServicesPage}
            />
          </>
        )}
      </div>
    </section>
  );
}
import React from 'react';
import './LocationDesktop.css';
// Components
import TutorialSteps from './TutorialSteps/TutorialSteps';
import Tutorial from './Tutorial/Tutorial';
// Resources
import { LocationDesktopProps } from '../../../resources/interfaces';

export default function LocationDesktop(props: LocationDesktopProps) {
  // Props
  const { locations, step, stepCount, setStep } = props;

  const getContent = (): string => (locations.length > 0) ? locations[step].content.rendered : '';

  const hasImage = (): string | null => (locations.length>0) ? locations[step].featured_image_src : null;

  return (
    <>
      <div id="tutorial-box" className="col-4">
        <TutorialSteps step={step} stepCount={stepCount} setStep={setStep} />
        <Tutorial
          content={getContent()}
          step={step}
          stepCount={stepCount}
          setStep={setStep}
        />
      </div>
      <div id="tutorial-item-image" className="pl-3 col-8">
        {hasImage() ? (
          <div>
            <img src={locations[step].featured_image_src} alt="tutorial" />
          </div>
        ) : null}
      </div>
    </>
  );
}
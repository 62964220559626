import React, { useContext } from 'react';
import './ViewsBoxItem.css';
// Resources
import { LanguageData, WPView } from '../../../../resources/interfaces';
// Context
import { LanguageContext } from '../../../../Context/LanguageContext';
// JSON
const languageData: LanguageData = require('../../../../resources/languageData.json');

interface Props {
  view: WPView;
}

export default function ViewsBoxItem(props: Props) {
  // Props
  const { view } = props;
  // Context
  const [language] = useContext(LanguageContext);
  // JSON
  const { openButton } = languageData.language[language].page['views'];

  return (
    <li
      key={view.id}
      className={'card col-4'}
    >
        <div
          className={'card-body'}
          style={{ backgroundImage: "url(background-image: url('/images/Logo_NDT_hor_v2.png')" }}
        ></div>
      <div className="card-footer text-muted">
        <div className="card-footer-container">
          <div className="view-item-title">
            <h5 dangerouslySetInnerHTML={{ __html: view.title.rendered }}></h5>
          </div>

          <div className="content mt-1">
            <div>
              <p>{view.acf.description}</p>
            </div>
          </div>
          <div className="card-link">
            <a
              href={view.acf.hyperlink_desktop}
              target="_blank"
              rel="noopener noreferrer"
            >
              {openButton}
            </a>
          </div>
        </div>
      </div>
    </li>
  );
}
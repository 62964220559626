import React from 'react';
import './ViewTags.css';
// Components
import TagsPagination from '../../../components/TagsPagination/TagsPagination';
// Resources
import { Tag,ViewTagsProps } from '../../../resources/interfaces';

export default function ViewTags(props: ViewTagsProps) {
  // Props
  const {
    tags,
    activeTag,
    tagPageCount,
    currentTagPage,
    setCurrentTagPage,
    onTagClick,
  } = props;
  // Variables
  const tagLimit = 10;

  const getTagName = (tag: Tag): string => tag.name ? tag.name : '';

  const decrementCurrentPage = (): void => {
    if (currentTagPage > 0) setCurrentTagPage(currentTagPage - 1);
  };

  const incrementCurrentPage = (): void => {
    const pageCount = Math.ceil(tags.length / tagLimit);
    if (currentTagPage < pageCount) setCurrentTagPage(currentTagPage + 1);
  };

  return (
    <div id="views-tag-box" className="col-3">
      <ul id="views-tag-list">
        {tags.map((tag) => {
          return (
            <li
              key={tag.id}
              onClick={() => onTagClick(tag.id)}
              className={
                tag.id === activeTag
                  ? 'views-tag-list-item active'
                  : 'views-tag-list-item'
              }
            >
              <p>{getTagName(tag)}</p>
            </li>
          );
        })}
      </ul>
      <TagsPagination
        tagPageCount={tagPageCount}
        currentPage={currentTagPage}
        handleLeftClick={decrementCurrentPage}
        handleRightClick={incrementCurrentPage}
      />
    </div>
  );
}
import React, { useContext, useState, useEffect } from 'react';
import './ServicesTagsSelector.css';
// Resources
import { Tag, LanguageData,ServicesTagsSelectorProps } from '../../../resources/interfaces';
// Context
import { LanguageContext } from '../../../Context/LanguageContext';
// JSON
const languageData: LanguageData = require('../../../resources/languageData.json');

export default function ServicesTagsSelector(props: ServicesTagsSelectorProps) {
  // Props
  const {
    tags,
    activeTags,
    tagPageCount,
    currentTagPage,
    setCurrentTagPage,
    onTagClick,
  } = props;
  // Context
  const [language] = useContext(LanguageContext);
  // Variables
  const element = document.getElementById('services-tags-selector-list');
  // JSON
  const { selectTagText } = languageData.language[language].page['services'];
  // State
  const [menuOpen, setMenuOpen] = useState<boolean>(false);

  const handleScroll = () => {
    let bottom: boolean = (element) ? ( element.scrollHeight - element.scrollTop - 50 ) <= element.clientHeight : false;

    if (bottom && currentTagPage + 1 < tagPageCount) setCurrentTagPage(currentTagPage + 1);
  };

  const getTag = (): JSX.Element | string => {
    if (tags.length) {
      const displayedTag = tags.find((tag: Tag) => tag.id === activeTags[0])
        ?.name;
      if (displayedTag) return <h4>{displayedTag}</h4>;
      else return <h4>{selectTagText}</h4>;
    } else return <h4>{selectTagText}</h4>;
  };

  const handleTagClick = (id: number): void => {
    onTagClick(id);
    setMenuOpen(false);
  };

  useEffect(() => {
    const scroll = setTimeout(() => {
      const element = document.getElementById('services-tags-selector-list');
      if (element) element.scrollTop = 1;
      if (element) element.scrollTop = 0;
    }, 1000);
    return () => {
      clearTimeout(scroll);
    };
  }, [menuOpen]);

  return (
    <div
      id="services-tags-selector"
      className="mb-3"
      onClick={() => setMenuOpen(!menuOpen)}
    >
      <div id="active-tag" className="pl-2">
        {getTag()}
      </div>
      <div id="arrow" className={`ml-2 mr-2 ${menuOpen ? 'open' : ''}`}>
        <img src="/images/arrow_down_white_icon.png" alt="open menu selector" />
      </div>
      <div
        id="services-tag-list-vertical"
        className={menuOpen ? 'open' : ''}
        style={{ width: '100%' }}
      >
        <ul
          id="services-tags-selector-list"
          className={menuOpen ? 'open' : ''}
          onScroll={handleScroll}
        >
          {tags.map((tag: Tag) => (
            <li
              key={tag.id}
              className={activeTags.includes(tag.id) ? 'active' : ''}
              onClick={() => handleTagClick(tag.id)}
            >
              {tag.name}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}
import React, { useContext } from 'react';
import './Tutorial.css';
// Context
import { LanguageContext } from '../../../../Context/LanguageContext';
// Resources
import { LanguageData,TutorialProps } from '../../../../resources/interfaces';

const languageData: LanguageData = require('../../../../resources/languageData.json');

export default function Tutorial(props: TutorialProps) {
  // Props
  const { content, step, stepCount, setStep } = props;
  // Context
  const [language] = useContext(LanguageContext);
  // JSON
  const { readMoreText } = languageData.language[language].page['locations'];

  return (
    <div id="tutorial">
      <article dangerouslySetInnerHTML={{ __html: content }}></article>
      {step === stepCount - 1 ? null : (
        <button className="location-readmore" onClick={() => setStep(step + 1)}>
          <div className="location-readmore-button mr-2">{readMoreText}</div>
          <div className="location-readmore-image">
            <img src="/images/arrow_down_white_icon.png" alt="" />
          </div>
        </button>
      )}
    </div>
  );
}
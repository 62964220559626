import React, { useState, useContext } from 'react';
import { Animated } from 'react-animated-css';
import './LanguageSelector.css';
// JSON
import languageData from '../../../resources/languageData.json';
// Context
import { LanguageContext } from '../../../Context/LanguageContext';

export default function LanguageSelector() {
  // Context
  const [language, setLanguage] = useContext(LanguageContext);
  // State
  const [open, setOpen] = useState<boolean>(false);

  const handleLanguageClick = (clickedLanguage: string): void => {
    if (open) setLanguage(clickedLanguage);
    setOpen(!open);
  };

  const getLanguages = (): JSX.Element[] | JSX.Element => {
    return (open) ? Object.keys(languageData.language).map((e) => (
        <Animated
          key={e}
          animationIn="zoomIn"
          animationOut="zoomOut"
          animationInDuration={500}
          animationOutDuration={500}
          isVisible={true}
        >
          <li onClick={() => handleLanguageClick(e)}>{e}</li>
        </Animated>
      ))
      :
      (
        <li key={language} onClick={() => handleLanguageClick(language)}>
          {language}
        </li>
      );
  }

  return (
    <ul
      id="language-selector"
      className={open ? 'ml-2 mr-3' : 'ml-2 mr-3 closed'}
    >
      {getLanguages()}
    </ul>
  );
}
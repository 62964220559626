import React from 'react';
import './LocationMobile.css';
// Resources
import { WPLocation,LocationMobileProps } from '../../../resources/interfaces';
// Components
import Loader from '../../../components/Loader/Loader';

export default function LocationMobile(props: LocationMobileProps) {
  // Props
  const { locations, isLoading } = props;

  return (
    <div id="locations-tutorial">
      {isLoading ? (
        <div>
          <Loader />
        </div>
      ) : (
        locations.map((location: WPLocation) => {
          return (
            <div key={location.id} className="locations-tutorial-item col-12">
              <div
                className="content pl-4 pr-4 pt-4 pb-4"
                dangerouslySetInnerHTML={{ __html: location.content.rendered }}
              ></div>
              <div className="image">
                <img src={location.featured_image_src} alt="" />
              </div>
            </div>
          );
        })
      )}
    </div>
  );
}
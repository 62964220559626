import React, { useState } from 'react';
// Resources
import { MenuItem } from '../../../../resources/interfaces';
import { Link } from 'react-router-dom';

interface Props {
  menuItem: MenuItem;
  setIsSidenavOpen: (open: boolean) => void;
}

export default function SidenavSubmenu(props: Props) {
  // Props
  const { menuItem, setIsSidenavOpen } = props;
  // State
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const getLocalLink = (title: string, link: string): JSX.Element => {
    return <Link to={link}>{title}</Link>;
  };

  const getExternalLink = (title: string, link: string): JSX.Element => {
    return (
      <a href={link} target="_blank" rel="noopener noreferrer">
        {title}
      </a>
    );
  };

  return (
    <li
      key={menuItem.title}
      className={`sidenav-item${isOpen ? ' open' : ''} mt-1 mb-1`}
      onClick={() => setIsOpen(!isOpen)}
    >
      <p className="sidenav-link sidenav-submenu-link">
        <span className="mr-1">
          <img src="/images/arrow_down_black_icon.png" alt="open submenu" />
        </span>
        {menuItem.title}
      </p>
      <ul className="sidenav-submenu-list mt-1 mb-1">
        {menuItem.submenu?.map((submenuItem) => (
          <li key={submenuItem.title} onClick={() => setIsSidenavOpen(false)}>
            {submenuItem.externalLink
              ? getExternalLink(submenuItem.title, submenuItem.link)
              : getLocalLink(submenuItem.title, submenuItem.link)}
          </li>
        ))}
      </ul>
    </li>
  );
}
import React from 'react';
import './TagsPagination.css';
import { TagsPaginationProps } from '../../resources/interfaces'

export default function TagsPagination(props: TagsPaginationProps) {
  // Props
  const {
    tagPageCount,
    currentPage,
    handleLeftClick,
    handleRightClick,
  } = props;
  // Variables
  const currentPageDisplay = currentPage + 1;
  const pageCountDisplay = tagPageCount;
  return (
    <>
      {tagPageCount > 1 ? (
        <div
          id="tag-list-pagination"
          className="row justify-content-center fade-in-slow"
        >
          <div className="col-8">
            <span
              onClick={() => handleLeftClick()}
              className="tag-page-left mr-2"
            >
              {'< '}
            </span>
            {currentPageDisplay} / {pageCountDisplay}
            <span
              onClick={() => handleRightClick()}
              className="tag-page-right ml-2"
            >
              {' >'}
            </span>
          </div>
        </div>
      ) : null}
    </>
  );
}
import React from 'react';
import './ViewsBox.css';
// Components
import ViewsBoxItem from './ViewsBoxItem/ViewsBoxItem';
import ViewsBoxSkeleton from './ViewsBoxSkeleton/ViewsBoxSkeleton';
// Resources
import { WPView,ViewsBoxProps } from '../../../resources/interfaces';


export default function ViewsBox(props: ViewsBoxProps) {
  // Props
  const {
    views,
    viewsIsLoading,
    pageCount,
    currentViewsPage,
    setCurrentViewsPage,
  } = props;

  const decrementCurrentPage = (): void => {
    if (currentViewsPage > 0) setCurrentViewsPage(currentViewsPage - 1);
  };

  const incrementCurrentPage = (): void => {
    if (currentViewsPage < pageCount - 1)
      setCurrentViewsPage(currentViewsPage + 1);
  };

/*
  const hasImage = (index: number): string | null => {
    const image = views[index].featured_image_src;
    return (views.length > 0 && image) ? image : null;
  };
*/
  const getLeftArrow = (): JSX.Element | null => {
    return (currentViewsPage === 0) ? null
    : (
        <div id="views-left-arrow" className="views-arrow">
          <img
            onClick={() => decrementCurrentPage()}
            src="/images/arrow_left.svg"
            alt="arrow left back"
          />
        </div>
      );
  };

  const getRightArrow = (): JSX.Element | null => {
    return (currentViewsPage === pageCount - 1 || pageCount === 0) ? null
    : (
        <div id="views-right-arrow" className="views-arrow">
          <img
            onClick={() => incrementCurrentPage()}
            src="/images/arrow_right.svg"
            alt="arrow right back"
          />
        </div>
      );
  };

  return (
    <div id="views-box" className="pl-3 col-9">
      {getLeftArrow()}
      <ul className="row">
        {viewsIsLoading ? (
          <ViewsBoxSkeleton />
        ) : (
          views.map((view: WPView, index: number) => (
            <ViewsBoxItem
              key={view.id}
              view={view}
            />
          ))
        )}
      </ul>
      {getRightArrow()}
    </div>
  );
}
import WPAPI from 'wpapi';
import { API } from '../api';

const { HOST_URL, API_URL } = API.url;
const wp = new WPAPI({ endpoint: HOST_URL + API_URL });

// Post: Services
wp.services = wp.registerRoute('wp/v2', '/services/(?P<id>\\d+)');
// Post: Views
wp.views = wp.registerRoute('wp/v2', '/views/(?P<id>\\d+)');
// Post: Locations
wp.locations = wp.registerRoute('wp/v2', '/locations/(?P<id>\\d+)');
// Taxonomy: tag_services
wp.tagServices = wp.registerRoute('wp/v2', '/tag_services/(?P<id>\\d+)');
// Taxonomy: tag_views
wp.tagViews = wp.registerRoute('wp/v2', '/tag_views/(?P<id>\\d+)');

/**
 * Callable fetch post functions:
 *  - services()
 */
export const Wordpress = wp;
import React from 'react';
import '../ServicesBoxItem/ServicesBoxItem.css';
import './ServicesBoxSkeleton.css';

export default function ServicesBoxSkeleton() {
  return (
    <>
      {[1, 2, 3, 4, 5, 6].map((item) => {
        return (
          <li key={item} className="card fade-in col-4">
            <div className="card-body">
              <p className="card-text services-loading-bg-gradient"></p>
            </div>
            <div className="footer services-loading-bg-gradient"></div>
          </li>
        );
      })}
    </>
  );
}
import React, { useState, useContext, useEffect } from 'react';
// Components
import ViewTags from './ViewTags/ViewTags';
import ViewsBox from './ViewsBox/ViewsBox';
import ViewTagsSelector from './ViewTagsSelector/ViewTagsSelector';
import ViewsBoxVertical from './ViewsBoxVertical/ViewsBoxVertical';
// Hooks
import useWindowDimensions from '../../Hooks/useWindowDimensions';
// Resources
import {
  LanguageData,
  Tag,
  WPTag,
  WPTagArray,
  WPView,
  WPArray,
} from '../../resources/interfaces';
// Context
import { LanguageContext } from '../../Context/LanguageContext';
import { Wordpress } from '../../resources/Wordpress';
// JSON
const languageData: LanguageData = require('../../resources/languageData.json');

export default function Views() {
  // Hooks
  const { isMobile } = useWindowDimensions();
  // Context
  const [language] = useContext(LanguageContext);
  // JSON
  const { title } = languageData.language[language].page['views'];
  // State - tags
  const [tags, setTags] = useState<Tag[]>([]);
  const [activeTag, setActiveTag] = useState<number>(0);
  const [tagPageCount, setTagPageCount] = useState<number>(0);
  const [currentTagPage, setCurrentTagPage] = useState<number>(0);
  // State - views
  const [viewsIsLoading, setViewsIsLoading] = useState<boolean>(false);
  const [views, setViews] = useState<WPView[]>([]);
  const [currentViewsPage, setCurrentViewsPage] = useState<number>(0);
  const [viewsPageCount, setViewsPageCount] = useState<number>(0);

  useEffect(() => {
    setTags([]);
    setActiveTag(0);
    setViews([]);
    setCurrentTagPage(0);
    setCurrentViewsPage(0);
  }, [isMobile, language]);

  useEffect(() => {
    let isCancelled = false;
    Wordpress.tagViews()
      .page(currentTagPage + 1)
      .perPage(isMobile ? 6 : 8)
      .then((response: WPTagArray) => {
        if (!isCancelled) {
          const newTags = response.map((tag: WPTag) => {
            const getTitle = (tag: WPTag): string => {
              const fallbackTitle = tag.name;
              const ptTitle = tag.name_pt;
              const enTitle = tag.name_en;
              if (language === 'PT')
                return ptTitle === ''
                  ? fallbackTitle
                  : ptTitle ?? fallbackTitle;
              else if (language === 'EN')
                return enTitle === ''
                  ? fallbackTitle
                  : enTitle ?? fallbackTitle;
              else return fallbackTitle ?? '';
            };
            return {
              id: tag.id,
              name: getTitle(tag),
            };
          });
          
          (isMobile && currentTagPage > 0) ?
            setTags(
              Array.from( new Set([...tags, ...newTags].filter((tag: WPTag) => tag.id)) )
            )
          : setTags(newTags);

          setTagPageCount(response._paging.totalPages);
        }
      })
      .catch((error: Error) => console.log(error));

    return () => {
      if (!isMobile && !(currentTagPage > 0)) isCancelled = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [language, currentTagPage, isMobile]);

  useEffect(() => {
    const parseResponse = (response: WPArray): WPView[] => {
      return response.map((view: WPView) => {
        return {
          id: view.id,
          title: {
            rendered: view.title.rendered,
          },
          content: {
            rendered: view.content.rendered,
          },
          featured_image_src: view.featured_image_src,
          acf: {
            hyperlink_desktop: view.acf.hyperlink_desktop,
            hyperlink_mobile: view.acf.hyperlink_mobile,
            description: view.acf.description,
          },
        };
      });
    };
    setViewsIsLoading(true);
    let isCancelled = false;
    Wordpress.views()
      .perPage(6)
      .page(currentViewsPage + 1)
      .param('lang', language)
      .param('tag_views', activeTag === 0 ? undefined : activeTag)
      .then((response: WPArray) => {
        if (!isCancelled) {

          (isMobile && currentViewsPage > 0) ?
            setViews([...views, ...parseResponse(response)])
          : setViews(parseResponse(response));

          setViewsPageCount(response._paging ? response._paging.totalPages : 0);
          setViewsIsLoading(false);
        }
      })
      .catch((error: Error) => console.log(error));

    return () => {
      if (!isMobile && !(currentTagPage > 0)) isCancelled = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [language, currentViewsPage, activeTag, isMobile]);

  const handleTagClick = (id: number): void => {
    setCurrentViewsPage(0);
    (activeTag === id) ? setActiveTag(0) : setActiveTag(id);
  };

  const handleSetCurrentViewsPage = (page: number): void => {
    if (!viewsIsLoading) setCurrentViewsPage(page);
  };

  return (
    <section className="section">
      <h2 className="section-title">{title}</h2>
      <div className="section-container">
        {!isMobile ? (
          <>
            <ViewTags
              tags={tags}
              tagPageCount={tagPageCount}
              activeTag={activeTag}
              currentTagPage={currentTagPage}
              setCurrentTagPage={setCurrentTagPage}
              onTagClick={handleTagClick}
            />
            <ViewsBox
              views={views}
              viewsIsLoading={viewsIsLoading}
              currentViewsPage={currentViewsPage}
              pageCount={viewsPageCount}
              setCurrentViewsPage={handleSetCurrentViewsPage}
            />
          </>
        ) : (
          <>
            <ViewTagsSelector
              tags={tags}
              tagPageCount={tagPageCount}
              activeTag={activeTag}
              currentTagPage={currentTagPage}
              setCurrentTagPage={setCurrentTagPage}
              onTagClick={handleTagClick}
            />
            <ViewsBoxVertical
              views={views}
              viewsIsLoading={viewsIsLoading}
              currentViewsPage={currentViewsPage}
              pageCount={viewsPageCount}
              setCurrentViewsPage={handleSetCurrentViewsPage}
            />
          </>
        )}
      </div>
    </section>
  );
}
import React, { useState, useEffect, useContext } from 'react';
import './Location.css';
import '../Pages.css';
// Components
import LocationDesktop from './LocationDesktop/LocationDesktop';
import LocationMobile from './LocationMobile/LocationMobile';
// Context
import { LanguageContext } from '../../Context/LanguageContext';
// Hooks
import useWindowDimensions from '../../Hooks/useWindowDimensions';
// Resources
import { Wordpress } from '../../resources/Wordpress';
import { WPLocation, LanguageData } from '../../resources/interfaces';

const languageData: LanguageData = require('../../resources/languageData.json');

export default function Location() {
  // Context
  const [language] = useContext(LanguageContext);
  // Hooks
  const { isMobile } = useWindowDimensions();
  // State
  const [step, setStep] = useState<number>(0);
  const [locations, setLocations] = useState<WPLocation[]>([]);
  const [locationsIsLoading, setLocationsIsLoading] = useState<boolean>(false);

  useEffect(() => {
    let isCancelled = false;
    setLocationsIsLoading(true);
    Wordpress.locations()
      .param('lang', language)
      .order('asc')
      .then((response: WPLocation[]) => {
        if (!isCancelled) {
          setStep(0);
          setLocations(response);
          setLocationsIsLoading(false);
        }
      })
      .catch((error: Error) => console.log(error));

    return () => {
      isCancelled = true;
    };
  }, [language]);

  const getTitle = (): string => {
    if (languageData.language[language]) {
      const title = languageData.language[language].page['locations'].title;
      return title ? title : '';
    } else {
      return '';
    }
  };

  const handleSetStep = (step: number): void => {
    if (step >= 0 && step < locations.length) setStep(step);
  };

  return (
    <section className={`section${isMobile ? ' scroll' : ''}`}>
      <h2 className="section-title">{getTitle()}</h2>
      <div className={`section-container${isMobile ? ' scrollable' : ''}`}>
        {isMobile ? (
          <LocationMobile
            locations={locations}
            isLoading={locationsIsLoading}
          />
        ) : (
          <LocationDesktop
            locations={locations}
            step={step}
            stepCount={locations.length}
            setStep={handleSetStep}
          />
        )}
      </div>
    </section>
  );
}
import React, { useContext, useState, useEffect } from 'react';
import InputGroup from 'react-bootstrap/InputGroup';
import FormControl from 'react-bootstrap/FormControl';
import Button from 'react-bootstrap/Button';
import './ServicesTags.css';
// Components
import TagsPagination from '../../../components/TagsPagination/TagsPagination';
// Context
import { LanguageContext } from '../../../Context/LanguageContext';
// Resources
import { Tag, LanguageData,ServiceTagsProps } from '../../../resources/interfaces';
// JSON
const languageData: LanguageData = require('../../../resources/languageData.json');

export default function ServiceTags(props: ServiceTagsProps) {
  // Props
  const {
    tags,
    activeTags,
    tagPageCount,
    currentTagPage,
    setCurrentTagPage,
    handleTagClick,
    handleInputSearch,
  } = props;
  // Context
  const [language] = useContext(LanguageContext);
  // JSON
  const { searchText, tagListText } = languageData.language[language].page[
    'services'
  ];
  // State
  const [searchInput, setSearchInput] = useState<string>('');

  const getTagName = (tag: Tag): string => tag.name ? tag.name : '';

  const decrementCurrentPage = (): void => {
    if (currentTagPage > 0) setCurrentTagPage(currentTagPage - 1);
  };

  const incrementCurrentPage = (): void => {
    if (currentTagPage + 1 < tagPageCount) setCurrentTagPage(currentTagPage + 1);
  };

  useEffect(() => {
    const fetch = setTimeout(() => {
      (searchInput.length >= 2) ? handleInputSearch(searchInput) : handleInputSearch('');
    }, 500);
    return () => {
      clearTimeout(fetch);
    };
  }, [searchInput, handleInputSearch]);

  return (
    <div id="services-tag-box" className="col-3" style={{ padding: '1.5rem' }}>
      <div id="service-search">
        <InputGroup>
          <FormControl
            placeholder={searchText}
            aria-label={searchText}
            aria-describedby="basic-addon1"
            onChange={(event) => setSearchInput(event.target.value)}
          />
          <InputGroup.Append>
            <Button>
              <img src="/images/loupe.svg" alt="loupe" className="icon" />
            </Button>
          </InputGroup.Append>
        </InputGroup>
      </div>
      <div id="services-tag-service-box-title">{tagListText}</div>
      <ul id="services-tag-list">
        {tags.map((tag) => {
          return (
            <li
              key={tag.id}
              onClick={() => handleTagClick(tag.id)}
              className={
                activeTags.includes(tag.id)
                  ? 'services-tag-list-item active mt-1'
                  : 'services-tag-list-item disable mt-1'
              }
            >
              <p>{getTagName(tag)}</p>
            </li>
          );
        })}
      </ul>
      <TagsPagination
        tagPageCount={tagPageCount}
        currentPage={currentTagPage}
        handleLeftClick={decrementCurrentPage}
        handleRightClick={incrementCurrentPage}
      />
    </div>
  );
}
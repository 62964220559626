const prod = {
  url: {
    HOST_URL: 'https://sigweb.cmnordeste.pt',
    API_URL: '/wp-json/',
  },
};

const dev = {
  url: {
    HOST_URL: 'http://localhost',
    API_URL: '/wp-json/',
  },
};

export const API = process.env.NODE_ENV === 'production' ? prod : dev;

export const isInDevelopmentMode = () => process.env.NODE_ENV === 'development';

import React, { useContext } from 'react';
import './ViewsBoxVertical.css';
// Components
import Loader from '../../../components/Loader/Loader';
// Context
import { LanguageContext } from '../../../Context/LanguageContext';
// Resources
import { LanguageData, ViewsBoxVerticalProps } from '../../../resources/interfaces';
// JSON
const languageData: LanguageData = require('../../../resources/languageData.json');

export default function ViewsBoxVertical(props: ViewsBoxVerticalProps) {
  // Props
  const {
    views,
    viewsIsLoading,
    pageCount,
    currentViewsPage,
    setCurrentViewsPage,
  } = props;
  // Context
  const [language] = useContext(LanguageContext);
  // JSON
  const { openButton } = languageData.language[language].page['views'];

  const shouldLoadMore = (): boolean => {
    const element = document.getElementById('views-box-vertical');
    return (element) ? element.scrollHeight - element.scrollTop - 50 <= element.clientHeight : false;
  };

  const handleScroll = () => {
    
    if (shouldLoadMore() && currentViewsPage + 1 < pageCount)
      setCurrentViewsPage(currentViewsPage + 1);
  };

  const hasImage = (index: number): string | null => {
    return (views.length > 0) ? views[index].featured_image_src : null;
  };

  return (
    <ul
      id="views-box-vertical"
      className={viewsIsLoading ? 'loading' : ''}
      style={{ width: '100%' }}
      onScroll={handleScroll}
    >
      {views.map((view, index) => (
        <li key={index} className="item row mb-3">
          {hasImage(index) ? (
            <div
              className={'card-body col-3'}
              style={{ backgroundImage: `url(${view.featured_image_src})` }}
            ></div>
          ) : null}
          <div
            className={
              hasImage(index)
                ? 'card-footer text-muted col-9'
                : 'card-footer text-muted col-12'
            }
          >
            <h5 dangerouslySetInnerHTML={{ __html: view.title.rendered }}></h5>
            <div className="content">
              <div
                dangerouslySetInnerHTML={{
                  __html: view.content.rendered,
                }}
              ></div>
            </div>
            <div className="card-link">
              <a
                href={view.acf.hyperlink_mobile}
                target="_blank"
                rel="noopener noreferrer"
              >
                {openButton}
              </a>
            </div>
          </div>
        </li>
      ))}
      {viewsIsLoading ? (
        <li>
          <Loader />
        </li>
      ) : null}
    </ul>
  );
}
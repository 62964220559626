import React, { useState, useEffect, useContext } from 'react';
// Resources
import { Wordpress } from '../../resources/Wordpress';
import { WPHomepage, WPImage, LanguageData } from '../../resources/interfaces';
// Components
import HomepageCarousel from './HomepageCarousel';
// Context
import { LanguageContext } from '../../Context/LanguageContext';
// JSON
const languageData: LanguageData = require('../../resources/languageData.json');

function Homepage() {
  // Context
  const [language] = useContext(LanguageContext);
  // JSON
  const { title, subTitle } = languageData.language[language].page['homepage'];
  // Slider images
  const [sliders, setSliders] = useState<WPImage[]>([]);

  useEffect(() => {
    
    let isCancelled = false; // Fetch homepage page data and set slider images
    
    Wordpress.pages()
      .id(2)
      .then((response: WPHomepage) => {

        if (!isCancelled)
            setSliders(response.acf.gallery ? response.acf.gallery : []);
        })
      .catch((error: Error) => console.log(error));

      return () => {
          isCancelled = true;
      };

      }, []);

  const getHomepageInfo = () => {
    return (sliders.length !== 0) ? 
      (
        <div id="homepage-info">
          <h1>{title}</h1>
          <h2>{subTitle}</h2>
        </div>
      ) 
      : null;
  };

  return (
    <section id="homepage">
      {getHomepageInfo()}
      <HomepageCarousel sliders={sliders} />
    </section>
  );
}

export default Homepage;
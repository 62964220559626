import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import './App.css';
// Pages
import Homepage from '../../Pages/Homepage/Homepage';
import Services from '../../Pages/Services/Services';
import Location from '../../Pages/Location/Location';
import Views from '../../Pages/Views/Views';
// Components
import Header from '../Header/Header';
// Context
import LanguageContextProvider from '../../Context/LanguageContext';

function App() {
  return (
    <LanguageContextProvider>
      <Router>
        <div id="app">
          <Switch>
            <Route path={['/visualizadores', '/viewers']}>
              <Header shouldRenderIndexLink={true} />
              <Views />
            </Route>
            <Route path={['/plantaslocalizacao', '/locationplans']}>
              <Header shouldRenderIndexLink={true} />
              <Location />
            </Route>
            <Route path={['/servicos', '/services']}>
              <Header shouldRenderIndexLink={true} />
              <Services />
            </Route>
            <Route path="/">
              <Header shouldRenderIndexLink={false} />
              <Homepage />
            </Route>
          </Switch>
        </div>
      </Router>
    </LanguageContextProvider>
  );
}

export default App;
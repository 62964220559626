import React, { useContext, useState, useEffect } from 'react';
import './ViewTagsSelector.css';
// Resources
import { Tag, LanguageData,ViewsTagSelectorProps } from '../../../resources/interfaces';
// Context
import { LanguageContext } from '../../../Context/LanguageContext';
// JSON
const languageData: LanguageData = require('../../../resources/languageData.json');


export default function ViewTagsSelector(props: ViewsTagSelectorProps) {
  // Props
  const {
    tags,
    activeTag,
    tagPageCount,
    currentTagPage,
    setCurrentTagPage,
    onTagClick,
  } = props;
  // Context
  const [language] = useContext(LanguageContext);
  // State
  const [menuOpen, setMenuOpen] = useState<boolean>(false);
  // JSON
  const { selectTagText } = languageData.language[language].page['views'];
  // Variables
  const element = document.getElementById('views-tags-selector-list');

  const getTag = (): JSX.Element | null => {
    if (tags.length) {
      const displayedTag =
        activeTag === 0
          ? selectTagText
          : tags.find((tag: Tag) => tag.id === activeTag)?.name;
      return <h4>{displayedTag}</h4>;
    }
    return null;
  };

  const handleScroll = () => {
    let bottom: boolean = false;
    if (element)
      bottom =
        element.scrollHeight - element.scrollTop - 50 <= element.clientHeight;
    if (bottom && currentTagPage + 1 < tagPageCount)
      setCurrentTagPage(currentTagPage + 1);
  };

  const handleTagClick = (id: number): void => {
    onTagClick(id);
    setMenuOpen(false);
  };

  useEffect(() => {
    const scroll = setTimeout(() => {
      const element = document.getElementById('views-tags-selector-list');
      if (element) element.scrollTop = 1;
      if (element) element.scrollTop = 0;
    }, 700);
    return () => {
      clearTimeout(scroll);
    };
  }, [menuOpen]);

  return (
    <div
      id="views-tags-selector"
      className="mb-3"
      onClick={() => setMenuOpen(!menuOpen)}
    >
      <div id="active-tag" className="pl-2">
        {getTag()}
      </div>
      <div id="arrow" className={`ml-2 mr-2 ${menuOpen ? 'open' : ''}`}>
        <img src="/images/arrow_down_white_icon.png" alt="open menu selector" />
      </div>
      <div
        id="views-tag-list-vertical"
        className={menuOpen ? 'open' : ''}
        onScroll={handleScroll}
      >
        <ul
          id="views-tags-selector-list"
          className={menuOpen ? 'open' : ''}
          onScroll={() => handleScroll()}
          style={{}}
        >
          {tags.map((tag: Tag) => (
            <li
              key={tag.id}
              className={tag.id === activeTag ? 'active' : ''}
              onClick={() => handleTagClick(tag.id)}
            >
              {tag.name}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}
import React, { useState, useEffect,useCallback } from 'react';
import './Homepage.css';
import Carousel from 'react-bootstrap/Carousel';
import { WPImage,HomepageCarouselProps } from '../../resources/interfaces';

function Homepage(props: HomepageCarouselProps) {
  // Props
  const { sliders } = props;
  // State
  const [carouselIndex, setCarouselIndex] = useState(0);

  const getNextCarouselIndex = useCallback( () => 
  { return (carouselIndex === sliders.length - 1) ? 0 : carouselIndex + 1}
  ,[carouselIndex,sliders]);

  useEffect(() => {
    const interval = setInterval(() => {
      setCarouselIndex(getNextCarouselIndex());
    }, 5000);
    return () => clearInterval(interval);
    // eslint-disable-next-line
  }, [sliders, getNextCarouselIndex]);

  return (
    <Carousel
      id="homepage-carousel"
      controls={false}
      indicators={false}
      activeIndex={carouselIndex}
    >
      {sliders.map((slider: WPImage, index: number) => {
        return (
          <Carousel.Item
            key={index}
            style={{ backgroundImage: `url(${slider.image})` }}
          />
          )
      })}
    </Carousel>
  );
}

export default Homepage;
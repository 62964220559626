import React, { useContext } from 'react';
import './Sidenav.css';
// Resources
import { MenuItem } from '../../../resources/interfaces';
import { Link } from 'react-router-dom';
// Context
import { LanguageContext } from '../../../Context/LanguageContext';
// Components
import SidenavSubmenu from './SidenavSubmenu/SidenavSubmenu';

interface Props {
  menuItems: MenuItem[];
  isOpen: boolean;
  setIsSidenavOpen: (open: boolean) => void;
}

export default function Sidenav(props: Props) {
  // Props
  const { menuItems, isOpen, setIsSidenavOpen } = props;
  // Context
  const [language, setLanguage] = useContext(LanguageContext);

  const handleLanguageClick = (newLanguage: string): void => {
    if (language !== newLanguage) {
      setLanguage(newLanguage);
      setIsSidenavOpen(false);
    }
  };

  const getLanguageSelector = (): JSX.Element => {
    return (
      <div id="language-selector-sidenav" className="mt-3">
        <button
          className={language === 'PT' ? 'active' : ''}
          onClick={() => handleLanguageClick('PT')}
        >
          PT
        </button>
        <span> / </span>
        <button
          className={language === 'EN' ? 'active' : ''}
          onClick={() => handleLanguageClick('EN')}
        >
          EN
        </button>
      </div>
    );
  };

  const getLocalLink = (title: string, link: string): JSX.Element => {
    return (
      <Link to={link} className="sidenav-link">
        {title}
      </Link>
    );
  };

  const getExternalLink = (title: string, link: string): JSX.Element => {
    return (
      <a
        href={link}
        target="_blank"
        rel="noopener noreferrer"
        className="sidenav-link"
      >
        {title}
      </a>
    );
  };

  return (
    <div id="sidenav" className={isOpen ? 'open' : ''}>
      <div id="sidenav-cross" onClick={() => setIsSidenavOpen(false)}>
        <img src="/images/cross.png" alt="sidemenu close" />
      </div>
      <div id="sidenav-links">
        <ul>
          {menuItems.map((menuItem: MenuItem) => {
            if (menuItem.link && menuItem.hasOwnProperty('externalLink')) {
              // External link
              if (menuItem.externalLink) {
                return (
                  <li
                    key={menuItem.title}
                    className="sidenav-item mt-1 mb-1"
                    onClick={() => setIsSidenavOpen(false)}
                  >
                    {getExternalLink(menuItem.title, menuItem.link)}
                  </li>
                );
              } // Local link
              else {
                return (
                  <li
                    key={menuItem.title}
                    className="sidenav-item mt-1 mb-1"
                    onClick={() => setIsSidenavOpen(false)}
                  >
                    {getLocalLink(menuItem.title, menuItem.link)}
                  </li>
                );
              }
              // Menu item with a submenu
            } else if (menuItem.submenu) {
              return (
                <SidenavSubmenu
                  key={menuItem.title}
                  menuItem={menuItem}
                  setIsSidenavOpen={setIsSidenavOpen}
                />
              );
            }
            return <li key={menuItem.title}>{menuItem.title}</li>;
          })}
        </ul>
        {getLanguageSelector()}
      </div>
    </div>
  );
}
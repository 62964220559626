import React from 'react';
import '../ViewsBoxItem/ViewsBoxItem.css';
import './ViewsBoxSkeleton.css';

export default function ViewsBoxSkeleton() {
  return (
    <>
      {[1, 2, 3, 4, 5, 6].map((item) => {
        return (
          <li key={item} className="card fade-in col-4">
            <div className="card-body">
              <div className="view-image views-loading-bg-gradient"></div>
            </div>
            <div className="footer views-loading-bg-gradient"></div>
          </li>
        );
      })}
    </>
  );
}
import React, { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import './Header.css';
// Resources
import { LanguageData, MenuItem,HeaderProps } from '../../resources/interfaces';
// Components
import LanguageSelector from './LanguageSelector/LanguageSelector';
import NavBar from './NavBar/NavBar';
import Sidenav from './Sidenav/Sidenav';
// Hooks
import useWindowDimensions from '../../Hooks/useWindowDimensions';
// Context
import { LanguageContext } from '../../Context/LanguageContext';
// JSON
const languageData: LanguageData = require('../../resources/languageData.json');


function Header(props: HeaderProps) {
  // Props
  const { shouldRenderIndexLink } = props;
  // Hooks
  const { isMobile } = useWindowDimensions();
  // Context
  const [language] = useContext(LanguageContext);
  // JSON
  const menuItems: MenuItem[] = languageData.language[language].header.links;
  // State
  const [isSidenavOpen, setIsSidenavOpen] = useState<boolean>(false);

  const getIndexLink = (): JSX.Element | null => {
    return (shouldRenderIndexLink) ? 
      (
        <div className="logo ml-5 flex-row">
          <Link to="/">
            <img src="/images/geonordeste_logo.png" alt="logo homepage" />
          </Link>
        </div>
       ) : null;
  };

  const getDesktopComponents = (menuItems: MenuItem[]): JSX.Element => {
    return (
      <>
        <NavBar menuItems={menuItems} />
        <LanguageSelector />
      </>
    );
  };

  const getMobileComponents = (): JSX.Element => {
    return (
      <>
        <div className="menu-burguer" onClick={() => setIsSidenavOpen(true)}>
          <img src="/images/burguer_icon.png" alt="sidemenu burguer open" />
        </div>
        <Sidenav
          menuItems={menuItems}
          isOpen={isSidenavOpen}
          setIsSidenavOpen={setIsSidenavOpen}
        />
      </>
    );
  };

  return (
    <header id="header" className="flex-row">
      <nav id="nav" className="flex-row">
        <div className="logo ml-5 flex-row">
          <img src="/images/Logo_NDT_hor_v2.png" alt="logo" />
        </div>
        {getIndexLink()}
        {isMobile ? getMobileComponents() : getDesktopComponents(menuItems)}
      </nav>
    </header>
  );
}

export default Header;
import React from 'react';
import './ServicesBoxVertical.css';
// Components
import Loader from '../../../components/Loader/Loader';
import ServicesBoxVerticalItem from './ServicesBoxVerticalItem/ServicesBoxVerticalItem';
// Resources
import { ServiceBoxVerticalProps } from '../../../resources/interfaces';

export default function ServicesBoxVertical(props: ServiceBoxVerticalProps) {
  // Props
  const {
    services,
    servicesIsLoading,
    pageCount,
    currentServicesPage,
    setCurrentServicesPage,
  } = props;

  const shouldLoadMore = (): boolean => {
    const element = document.getElementById('services-box-vertical');
    return (element) ? ( element.scrollHeight - element.scrollTop - 50 ) <= element.clientHeight : false;
  };

  const handleScroll = () => {
    
    if (shouldLoadMore() && currentServicesPage + 1 < pageCount)
      setCurrentServicesPage(currentServicesPage + 1);
  };

  return (
    <ul
      id="services-box-vertical"
      className={servicesIsLoading ? 'loading' : ''}
      onScroll={handleScroll}
    >
      {services.map((service, index) => {
        return <ServicesBoxVerticalItem key={index} service={service} />;
      })}
      {servicesIsLoading ? (
        <li>
          <Loader />
        </li>
      ) : null}
    </ul>
  );
}
import React, { useContext, useEffect, useState } from 'react';
import './ServicesBox.css';
// Components
import ServicesBoxItem from './ServicesBoxItem/ServicesBoxItem';
import ServicesBoxSkeleton from './ServicesBoxSkeleton/ServicesBoxSkeleton';
// Resources
import {
  WPService,
  WPTag,
  WPTagArray,ServicesBoxProps
} from '../../../resources/interfaces';
import { Wordpress } from '../../../resources/Wordpress';
// Context
import { LanguageContext } from '../../../Context/LanguageContext';


export default function ServicesBox(props: ServicesBoxProps) {
  // Props
  const {
    services,
    servicesIsLoading,
    pageCount,
    currentServicesPage,
    setCurrentServicesPage,
  } = props;
  // Context
  const [language] = useContext(LanguageContext);
  // State
  const [tags, setTags] = useState<WPTag[]>([]);

  const decrementCurrentPage = (): void => {
    if (currentServicesPage > 0) setCurrentServicesPage(currentServicesPage - 1);
  };

  const incrementCurrentPage = (): void => {
    if (currentServicesPage < pageCount - 1) setCurrentServicesPage(currentServicesPage + 1);
  };

  const getLeftArrow = (): JSX.Element | null => {
    return (currentServicesPage === 0) ? null
      :
      (
        <div id="services-left-arrow" className="services-arrow">
          <img
            onClick={() => decrementCurrentPage()}
            src="/images/arrow_left.svg"
            alt="arrow left back"
          />
        </div>
      );
  };

  const getRightArrow = (): JSX.Element | null => {
    return (currentServicesPage === pageCount - 1 || pageCount === 0) ? null
      :
      (
        <div id="services-right-arrow" className="services-arrow">
          <img
            onClick={() => incrementCurrentPage()}
            src="/images/arrow_right.svg"
            alt="arrow right back"
          />
        </div>
      );
  };

  useEffect(() => {
    const fetchServiceTags = async (page: number): Promise<WPTagArray> => {
      return Wordpress.tagServices()
        .perPage(100)
        .page(page)
        .then((response: WPTagArray) => response)
        .catch((error: Error) => console.log(error));
    };

    const filterServiceTagsResponse = (response: WPTagArray): WPTag[] => {
      return response.map((tag: WPTag) => {
        return {
          id: tag.id,
          name: tag.name,
        };
      });
    };

    let isCancelled = false;
    // Fetch the first page of tags
    fetchServiceTags(1).then((response) => {
      let fetchedTags: WPTag[] = [];
      // Save first page of tags on array
      fetchedTags.push(...filterServiceTagsResponse(response));

      // If there's more than 1 page of tags then fetch all the remaining pages
      if (response._paging.totalPages > 1) {
        // Function that fetches all the rest of the tags based on how many pages of tags there is
        // Fetches all pages asynchronously and joins all the responses into an array of tags
        const fetchRemainingServiceTags = async (): Promise<WPTag[]> => {
          let requests = Array.from(
            Array(response._paging.totalPages - 1).keys()
          ).map((page) => fetchServiceTags(page + 2));
          return Promise.all(requests).then((responses) => {
            let joinedResponses: WPTag[] = [];
            responses.forEach((response) =>
              joinedResponses.push(...filterServiceTagsResponse(response))
            );
            return joinedResponses;
          });
                  
        };
        
        // Fetch remaining Service Tags and add them to the state in conjuction with the ones from the first page
        fetchRemainingServiceTags().then((result) => {
          if (!isCancelled) setTags([...fetchedTags, ...result]);
        });

      }
      
      else {
        if (!isCancelled) setTags([...fetchedTags]);
      }

    });
    return () => {
      isCancelled = true;
    };
  }, [language]);

  const getTagNames = (tagList: number[]): string[] => {
    return tags
      .filter((tag) => tagList.includes(tag.id))
      .map((tag, index, array) => {
        return (array.length - 1 === index) ? tag.name : tag.name + ', ';
      });
  };

  return (
    <div id="services-box" className="pl-3 col-9">
      {getLeftArrow()}
      <ul className="row">
        {servicesIsLoading ? (
          <ServicesBoxSkeleton />
        ) : (
          services.map((service: WPService, index: number) => {
            const tagList = getTagNames(service.tag_services);
            return (
              <ServicesBoxItem
                key={index}
                tagList={tagList}
                service={service}
              />
            );
          })
        )}
      </ul>
      {getRightArrow()}
    </div>
  );
}
import React from 'react';
import { Link } from 'react-router-dom';
// Resources
import { MenuItem,NavBarProps } from '../../../resources/interfaces';

export default function NavBar(props: NavBarProps) {
  // Props
  const { menuItems } = props;

  const getLocalLink = (title: string, link: string): JSX.Element => {
    return <Link to={link}>{title}</Link>;
  };

  const getExternalLink = (title: string, link: string): JSX.Element => {
    return (
      <a href={link} target="_blank" rel="noopener noreferrer">
        {title}
      </a>
    );
  };

  return (
    <ul id="nav-links" className="flex-row">
      {menuItems.map((menuItem: MenuItem) => {
        // Menu item with a link
        if (menuItem.link && menuItem.hasOwnProperty('externalLink')) {
          // External link
          if (menuItem.externalLink) {
            return (
              <li key={menuItem.title} className="menu-child link pl-2 pr-2">
                {getExternalLink(menuItem.title, menuItem.link)}
              </li>
            );
          } // Local link
          else {
            return (
              <li key={menuItem.title} className="menu-child link pl-2 pr-2">
                {getLocalLink(menuItem.title, menuItem.link)}
              </li>
            );
          }
          // Menu item with a submenu
        } else if (menuItem.submenu) {
          return (
            <li key={menuItem.title} className="menu-child submenu">
              <p className="link pl-3 pr-3">{menuItem.title}</p>
              <ul id="external-links-menu" className="pt-3">
                {menuItem.submenu.map((subMenuItem) => (
                  <li key={subMenuItem.title} className="p-1">
                    {subMenuItem.externalLink
                      ? getExternalLink(subMenuItem.title, subMenuItem.link)
                      : getLocalLink(subMenuItem.title, subMenuItem.link)}
                  </li>
                ))}
              </ul>
            </li>
          );
        } else return null;
      })}
    </ul>
  );
}
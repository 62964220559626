import React, { useContext } from 'react';
import Button from 'react-bootstrap/esm/Button';
import './ServiceTypeSelector.css';
// Resources
import { LanguageData,ServiceTypeSelectorProps } from '../../../resources/interfaces';
// Context
import { LanguageContext } from '../../../Context/LanguageContext';
// Hooks
import useWindowDimensions from '../../../Hooks/useWindowDimensions';
// JSON
const languageData: LanguageData = require('../../../resources/languageData.json');


export default function ServiceTypeSelector(props: ServiceTypeSelectorProps) {
  // Hooks
  const { isMobile } = useWindowDimensions();
  // Props
  const { serviceType, setServiceType } = props;
  // Context
  const [language] = useContext(LanguageContext);
  // JSON
  const { serviceTypeText } = languageData.language[language].page['services'];

  return (
    <div
      id="services-types"
      style={!isMobile ? { bottom: '1rem' } : { bottom: '0rem' }}
    >
      <Button
        onClick={() => setServiceType('WMS')}
        className={serviceType === 'WMS' ? 'active' : ''}
      >
        {serviceTypeText} WMS
      </Button>
      <Button
        onClick={() => setServiceType('WFS')}
        className={serviceType === 'WFS' ? 'active' : ''}
      >
        {serviceTypeText} WFS
      </Button>
    </div>
  );
}